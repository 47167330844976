<template>
  <div class="home">
    <pHeader />
    <router-view></router-view>
    <pFooter />
  </div>
</template>

<script>
import pHeader from "@/components/pc/p-header/p-header.vue";
import pFooter from "@/components/pc/p-footer/p-footer"

export default {
  name: "Home",
  components: {
    pHeader,
    pFooter
  },
};
</script>
<style scoped>
.homeheader {
  margin-bottom: 20px;
}
</style>